import app from './feathersClient'
import {
  flow,
  compact,
  join,
  reduce,
  curry,
  overSome,
  negate,
  isPlainObject,
  isArray,
  isNil,
  filter,
  merge,
  difference,
  fromPairs,
  keys,
  isEqual,
  pickBy,
  get,
  identity,
  startCase,
  omit,
  camelCase,
  tap,
  set,
  last
} from 'lodash'
import {
  schemaPropToElement,
  removeUnfilterableProps
} from './components/FeathersCRUD'
import { startOfDay, endOfDay, parseISO } from 'date-fns'
import { ClientApplication } from '@nclusion/feathers-client'

export const compactJoin = (glue: string, vals: string[]) =>
  flow(compact, (val: string[]) => join(val, glue))(vals)

const dotJoinWith = (fn: any) => (x: any) => join(filter(x, fn), '.')
const isFlatObject = (obj: any) =>
  flow((fns) => overSome(obj, fns), negate)([isPlainObject, isArray])(obj)
const singleObject = curry((value: any, key: any) => ({ [key]: value }))

export const flattenObject = (input: any, paths?: any): any =>
  reduce(
    input,
    (output, value, key) =>
      merge(
        output,
        (isFlatObject(value) ? singleObject : flattenObject)(
          value,
          dotJoinWith(negate(isNil))([paths, key])
        )
      ),
    {}
  )

export const unflattenObject = (flattedObject: any) => {
  let result = {}
  keys(flattedObject).forEach((key) => set(result, key, flattedObject[key]))
  return result
}

const keyDiff = (obj1: any, obj2: any): any[] =>
  difference(
    keys(pickBy(flattenObject(obj1), identity)),
    keys(pickBy(flattenObject(obj2), identity))
  )

const flatObjectDiff = (obj1: any, obj2: any): any =>
  pickBy(
    flattenObject(obj1),
    (val, key) => !isEqual(val, get(obj2, key)) && !isNil(val)
  )

export const diffToSetAndUnset = (obj1: any, obj2: any) => {
  return {
    $set: flatObjectDiff(obj1, obj2),
    $unset: fromPairs(keyDiff(obj2, obj1).map((val) => [val, null]))
  }
}

type Operators = {
  _gte?: string
  _lte?: string
  _gt?: string
  _lt?: string
}
type Filters = {
  [key: string]: string | number | Operators
}
const operators: Operators = {
  _gte: '$gte',
  _gt: '$gt',
  _lte: '$lt',
  _lt: '$lt'
}

export const transformDateFields = (filter: Filters) => {
  const filters: Filters = {}

  Object.keys(filter).forEach((key: string) => {
    const operator =
      operators[`_${last(key.split('_'))}` as keyof typeof operators]
    const [, ...fieldPieces]: string[] = key.split('_').reverse()
    const field = fieldPieces.join('_')

    // we need to do something so this only sometimes does `start/end of day`
    operator
      ? filters[field]
        ? (filters[field] = {
            ...(filters[field] as object),
            [operator]: operator.includes('g')
              ? startOfDay(parseISO(filter[key] as string))
              : endOfDay(parseISO(filter[key] as string))
          })
        : (filters[field] = {
            [operator]: operator.includes('g')
              ? startOfDay(parseISO(filter[key] as string))
              : endOfDay(parseISO(filter[key] as string))
          })
      : (filters[key] = filter[key])
  })

  return filters
}

export const formatDrawingName = (drawingName: string) => {
  const drawingNameSplit = drawingName.split(' ')[0].split('.')

  return `${drawingNameSplit[2]?.toUpperCase() || ''} ${startCase(
    drawingNameSplit[3]
  )}`
}

export const formatLabel = (name: string, target: string, location?: number) =>
  startCase(name.split(target)[location || 1])

export const schemaFilters = (
  resource: string,
  additionalFilters: any = [],
  omittedFields?: string[]
) => {
  const schemas = app.get('schemas')
  if (!schemas) return

  let schema = schemas[camelCase(resource)]

  if (!schema) {
    return []
  }

  if (omittedFields) {
    schema = {
      ...schema,
      properties: omit(schema.properties, omittedFields)
    }
  }

  const filters = Object.keys(removeUnfilterableProps(schema).properties).map(
    schemaPropToElement(
      'create',
      removeUnfilterableProps(schema),
      schemas,
      true
    )
  )

  // Defense against undefined filters
  const outputFilters = [...additionalFilters, ...filters].filter((d) => !!d)
  return outputFilters
}

export const urlQueryParams = () => {
  const query = window.location.href.split('?')[1]
  if (!query) {
    return {}
  }

  return fromPairs(query.split('&').map((chunk) => chunk.split('=')))
}

export const currencyToLocale = (currency: string) =>
  ({
    HTG: 'fr-HT',
    DOP: 'es-DO',
    USD: 'en-US'
  }[currency])

const expToSymbol = (exp: string, isNegative: boolean) =>
  isNegative ? '' : ['', 'k', 'M', 'B', 'T'][Number(exp) / 3]

export const formatCurrency = (
  n: number,
  currency: string = 'USD',
  returnString?: boolean,
  exact?: boolean,
  includeSymbol?: boolean
) => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale

  if (exact) {
    return Intl.NumberFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
      ...(includeSymbol ? { style: 'currency' } : {}),
      currency
    }).format(n)
  }

  const elements = Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumSignificantDigits: 3,
    notation: 'engineering'
  }).formatToParts(n)

  const currencySymbol = elements.find(({ type }) => type === 'currency')!.value
  const integer = elements.find(({ type }) => type === 'integer')!.value
  const exp = elements.find(({ type }) => type === 'exponentInteger')!.value
  const isNegative = !!elements.find(({ type }) => type === 'exponentMinusSign')
    ?.value

  if (returnString) {
    return `${currencySymbol}${isNegative ? '.' : ''}${integer}${expToSymbol(
      exp,
      isNegative
    )}`
  }

  const smallSymbol = currencySymbol.length > 1

  return (
    <span>
      <span style={{ fontSize: smallSymbol ? '33%' : '100%' }}>
        {currencySymbol}
      </span>
      <span>{integer}</span>
      <span style={{ fontSize: '66%', marginLeft: 4 }}>
        {expToSymbol(exp, isNegative)}
      </span>
    </span>
  )
}

export const formatNumber = (
  n: number,
  locale?: string,
  returnString?: boolean,
  exact?: boolean
) => {
  if (exact) {
    return Intl.NumberFormat(locale, {
      maximumSignificantDigits: 1
    }).format(n)
  }

  const elements = Intl.NumberFormat(locale, {
    maximumSignificantDigits: 3,
    notation: 'engineering'
  }).formatToParts(n)

  const integer = elements.find(({ type }) => type === 'integer')!.value
  const exp = elements.find(({ type }) => type === 'exponentInteger')!.value
  const isNegative = !!elements.find(({ type }) => type === 'exponentMinusSign')
    ?.value

  if (returnString) {
    return `${isNegative ? '.' : ''}${integer}${expToSymbol(exp!, isNegative)}`
  }

  return (
    <span>
      <span>
        ${isNegative ? '.' : ''}
        {integer}
      </span>
      <span style={{ fontSize: '66%', marginLeft: 4 }}>
        {expToSymbol(exp, isNegative)}
      </span>
    </span>
  )
}

export const getLocale = () => Intl.DateTimeFormat().resolvedOptions().locale

export const formatNumbersOrSales = (value: number, currency?: string) => {
  const loc = getLocale()

  const num = Intl.NumberFormat(loc, {
    minimumFractionDigits: currency ? 2 : 0,
    maximumFractionDigits: currency ? 2 : 0
  }).format(value)

  return num
}

export const formatWinningNumbersString = (winningNumbers: string) =>
  `${winningNumbers.substring(0, 3)} ${winningNumbers.substring(3)}`

const propFromSource = (source: string) =>
  source.replace(/_day_gte|_day_lt|_gte|_lt/, '')

export const handleComparisonFilters = async (
  service: Parameters<ClientApplication['service']>[0],
  filters: Filters
) => {
  const schema = (await app.get('schemas'))[camelCase(service)]

  return Object.entries(filters).reduce(
    (serverFilters: any, [key, value]: [string, any]) => {
      if (!key.endsWith('_gte') && !key.endsWith('_lt')) {
        serverFilters[key] = value
        return serverFilters
      }

      const operator = (val: any) =>
        key.endsWith('_gte') ? { $gte: val } : { $lt: val }
      let adjust = (a: any) => a
      const propName = propFromSource(key)
      const prop = schema.properties[propName]

      if (
        prop.anyOf?.find(
          ({ instanceOf }: { instanceOf: string }) => instanceOf === 'Date'
        ) ||
        prop.instanceOf === 'Date'
      ) {
        if (key.endsWith('_day_gte') || key.endsWith('_day_lt')) {
          adjust = key.endsWith('_gte') ? startOfDay : endOfDay
        }

        serverFilters[propName] = serverFilters[propName] || {}
        Object.assign(
          serverFilters[propName],
          operator(adjust(parseISO(value)))
        )

        return serverFilters
      } else {
        serverFilters[propName] = serverFilters[propName] || {}
        Object.assign(serverFilters[propName], operator(value))

        return serverFilters
      }
    },
    {}
  )
}

export const safeParseJSON = (str: string) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return str
  }
}
