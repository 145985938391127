import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { PostHogProvider } from 'posthog-js/react'

const options = {
  api_host:
    process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com'
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={
        process.env.REACT_APP_PUBLIC_POSTHOG_KEY ||
        'phc_4p0hOE3rHHPGoZrACCEezCUXPHXnasZriXvnZ0oqKoA'
      }
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
)
