import { useEffect, useState } from 'react'
import ChartContext from './ChartContext'
import Search from './Search'
import { ClientApplication } from '@nclusion/feathers-client'
import { useLocation } from 'react-router-dom'

export default ({ children, storageKey, listen, currency, initial }: { children: JSX.Element | JSX.Element[], initial?: string, storageKey?: string, listen?: (Parameters<ClientApplication['service']>[0])[], currency?: string }) => {
  const [search] = useState<any>(new Search({ storageKey, listen, currency }))
  const location = useLocation()
  const [route, setRoute] = useState('')

  useEffect(() => {
    if (!route) {
      setRoute(location.pathname)
    } else {
      if (window.location.href.indexOf(location.pathname) < 0) {
        search.destructor()
      }
    }

    return () => {
      setTimeout(() => {
        if (window.location.href.indexOf(location.pathname) < 0) {
          search.destructor()
        }
      }, 500)
    }
  }, [location.pathname])

  return search && <ChartContext.Provider value={{ search }}>
    {children}
  </ChartContext.Provider>
}