import {
  Show as RAShow,
  Create as RACreate,
  Edit as RAEdit,
  Datagrid,
  TextField,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleShowLayout,
  SimpleForm,
  useTranslate,
  RecordRepresentation,
  useRecordContext,
  Link,
  EmailField,
  UrlField,
  NumberField,
  DateField,
  BooleanField,
  FunctionField
} from 'react-admin'
import { ImageField } from './ImageField'
import app from '../feathersClient'
import { Static } from '@nclusion/feathers-client'
import React from 'react'
import { createPortal } from 'react-dom'
import SelectOrCreateInModal from './SelectOrCreateInModal'
import {
  first,
  kebabCase,
  startCase,
  flow,
  get,
  lowerFirst,
  isNil
} from 'lodash'
import PhoneField from './PhoneField'
import DynamicReferenceFilter from './DynamicReferenceFilter'
import PartialMatchFilter from './PartialMatchFilter'
import { NclusionList } from './NclusionList'
import { ImageInputController } from './ImageInputController'
import { getLocale } from '../util'
import { NumberFilter } from './NumberFIlter'
import { DateFilter } from './DateFilter'

type DisplayMode = 'show' | 'create' | 'edit'

const isNullType = (type: any) => type === 'null'

const transforms = {
  lls$: 'accounts-lls',
  'safe-box$': 'accounts-safe-box',
  depository$: 'accounts-depository',
  'associate-cash$': 'accounts-associate-cash',
  'associate-credit$': 'accounts-associate-credit',
  'bearer-instrument$': 'accounts-bearer-instrument',
  user$: 'users'
}
const transform = (s: string) =>
  Object.entries(transforms).reduce(
    (p, c) => p.replace(new RegExp(c[0]), c[1]),
    s
  )

export const getType = (prop: any): any => {
  const $ref = prop.anyOf ? prop.anyOf[0].$ref : prop.$ref
  if ($ref) {
    return { $ref }
  }

  const typeArray = prop.anyOf || [prop]
  const types = typeArray.map((t: any) => t.type)

  const nullable = types.find(isNullType)
  const remaining = types.filter((type: string) => !isNullType(type))

  let type = remaining[0]
  const typeInfo = typeArray.find((val: any) => val.type === type)
  const readOnly = typeInfo.readOnly
  const createable = typeInfo.createable
  const computed = typeInfo.computed

  let vals: string[] | null = null

  if (typeInfo?.const) {
    vals = typeArray.map((type: any) => type.const).filter((val: any) => val)
  }

  if (type === 'object') {
    type = typeInfo?.instanceOf?.toLowerCase()
  }

  return {
    type,
    nullable,
    readOnly,
    createable,
    computed,
    ...(vals ? { enum: vals } : {})
  }
}

export const getFieldType = (resource: string, source: string) => {
  const schemas = app.get('schemas') || {}
  const schema = schemas[resource]

  return getType(schema?.properties[source])
}

const isRequired = (nullable: boolean, required: boolean, message: string) =>
  function <Entity>(val: any, record: Entity) {
    if ((required && isNil(val)) || (!nullable && isNil(val))) {
      return message
    }
  }

const CanoncalRecordRepresentation = ({ source = '', resource = '' }) => {
  const record = useRecordContext()
  const object = get(record, source)

  return (
    object && (
      <div onClick={(e: any) => e.stopPropagation()}>
        <Link to={`/${resource}/${object?.id || record[`${source}_id`]}/show`}>
          <RecordRepresentation
            key={source}
            record={object}
            resource={resource}
          />
        </Link>
      </div>
    )
  )
}
// this needs to at least handle arrays if it isn't fully nested
export const schemaPropToElement = function <
  Entity extends Record<keyof Entity, any>
>(
  mode: DisplayMode,
  schema: any,
  schemas: any,
  filterMode?: boolean,
  portalOptions?: Record<string, any>
) {
  return (propName: string) => {
    const translate = useTranslate()
    const prop = schema.properties[propName as string] || {}
    let element = <></>

    const {
      type,
      nullable,
      createable,
      readOnly,
      computed,
      enum: _enum,
      $ref
    } = getType(prop)

    if (mode === 'show') {
      if ($ref) {
        element = (
          <FunctionField
            key={propName}
            label={startCase(propName)}
            render={() => (
              <CanoncalRecordRepresentation
                source={propName}
                key={propName}
                resource={flow(lowerFirst, kebabCase, (str = '') =>
                  transform(str)
                )($ref)}
              />
            )}
            sortable={false}
          />
        )
      } else {
        switch (type) {
          case 'string':
            if (prop.subType) {
              switch (prop.subType) {
                case 'phone':
                  element = (
                    <PhoneField
                      key={propName}
                      source={propName}
                      sortable={!computed}
                    />
                  )
                  break
                case 'email':
                  element = (
                    <EmailField
                      key={propName}
                      source={propName}
                      sortable={!computed}
                    />
                  )
                  break
                case 'image_src':
                  // TODO: handle single & multiple image using AuthenciatedImage component
                  element = (
                    <ImageField
                      key={propName}
                      source={propName}
                      sortable={!computed}
                    />
                  )
                  break
                case 'url':
                  element = (
                    <UrlField
                      key={propName}
                      source={propName}
                      sortable={!computed}
                    />
                  )
                  break
                default:
                  element = (
                    <TextField
                      key={propName}
                      source={propName}
                      sortable={!computed}
                    />
                  )
                  break
              }
            } else {
              element = (
                <TextField
                  key={propName}
                  source={propName}
                  sortable={!computed}
                />
              )
            }
            break
          case 'date':
            element = (
              <DateField
                key={propName}
                source={propName}
                showTime={true}
                sortable={!computed}
              />
            )
            break
          case 'number':
            element = (
              <NumberField
                key={propName}
                source={propName}
                sortable={!computed}
                locales={getLocale()}
              />
            )
            break
          case 'boolean':
            element = (
              <BooleanField
                key={propName}
                source={propName}
                sortable={!computed}
              />
            )
            break
          default:
            element = (
              <TextField
                key={propName}
                source={propName}
                sortable={!computed}
              />
            ) // create a default show element
            break
        }
      }
    } else {
      if (
        computed ||
        (!filterMode &&
          (!type ||
            (mode === 'edit' && readOnly) ||
            (mode === 'create' && !createable)))
      ) {
        element = <React.Fragment key={propName}></React.Fragment>
      } else if (_enum) {
        element = (
          <SelectInput
            key={propName}
            source={propName}
            choices={_enum.map((val: any) => ({
              id: val,
              name: typeof val === 'string' ? startCase(val) : val
            }))}
          />
        )
      } else if (prop.references) {
        let [reference] = first(prop.references) as [string, string]
        reference = reference.replace(/user$/, 'users')

        if (prop.subType) {
          if (prop.subType == 'image_src') {
            const uploaderFormId = `uploader-${portalOptions?.resource}-${propName}`
            element = (
              <ImageInputController
                key={`image-input-controller-${propName}`}
                propName={propName}
                formId={uploaderFormId}
                portalOptions={portalOptions}
                subTypeOptions={{ ...prop.subTypeOptions }}
              />
            )
          } else if (prop.subType == 'upload_src') {
            const uploaderFormId = `uploader-${portalOptions?.resource}-${propName}`
            // keep separate from image_src in case diff attributes needed
            element = (
              <ImageInputController
                key={`image-input-controller-${propName}`}
                propName={propName}
                formId={uploaderFormId}
                portalOptions={portalOptions}
                subTypeOptions={{ ...prop.subTypeOptions }}
              />
            )
          } else {
            // TODO:
          }
        } else if (prop.type === 'array') {
          element = (
            <ReferenceArrayInput
              key={propName}
              source={propName}
              reference={`${
                schemas[reference]?.category
                  ? `${schemas[reference].category}-`
                  : ''
              }${kebabCase(reference)}`} // perfix with category as in App.js
            />
          )
        } else {
          element = (
            <SelectOrCreateInModal
              key={propName}
              source={propName}
              schema={schema}
              disableCreate={true}
              reference={`${
                schemas[reference]?.category
                  ? `${schemas[reference].category}-`
                  : ''
              }${kebabCase(reference)}`}
            />
          )
        }
      } else {
        switch (type) {
          case 'string':
            // TODO: once confirmed that the image_src subTypes will always be references, remove this code
            if (prop.subType) {
              if (prop.subType == 'image_src' || prop.subType == 'upload_src') {
                // TODO: replace with parametric id since there may be more than one on page
                const uploaderFormId = `uploader-${propName}`

                element = (
                  <ImageInputController
                    key={`image-input-controller-${propName}`}
                    propName={propName}
                    formId={uploaderFormId}
                    portalOptions={portalOptions}
                    subTypeOptions={{ ...prop.subTypeOptions }}
                  />
                )
              }
            } else if (prop.dynamicReference && filterMode) {
              element = (
                <DynamicReferenceFilter
                  key={propName}
                  source={propName}
                  reference={prop.dynamicReference}
                />
              )
            } else if (filterMode) {
              element = <PartialMatchFilter key={propName} source={propName} />
            } else {
              element = (
                <TextInput
                  key={propName}
                  source={propName}
                  resettable
                  validate={[
                    mode === 'create'
                      ? isRequired(
                          nullable,
                          schema?.required?.includes(propName),
                          translate('nclusion.required')
                        )
                      : () => null
                  ]}
                  {...(schema?.required?.indexOf(propName) >= 0
                    ? { required: true }
                    : {})}
                />
              )
            }
            break
          case 'number':
            if (prop.dynamicReference && filterMode) {
              element = (
                <DynamicReferenceFilter
                  key={propName}
                  source={propName}
                  reference={prop.dynamicReference}
                />
              )
            } else {
              if (filterMode) {
                element = <NumberFilter key={propName} source={propName} />
              } else {
                element = (
                  <NumberInput
                    type="number"
                    key={propName}
                    source={propName}
                    validate={[
                      mode === 'create'
                        ? isRequired(
                            nullable,
                            schema?.required?.includes(propName),
                            translate('nclusion.required')
                          )
                        : () => null
                    ]}
                    {...(schema?.required?.indexOf(propName) >= 0 && !filterMode
                      ? { required: true }
                      : {})}
                  />
                )
              }
            }
            break
          case 'date':
            if (filterMode) {
              element = <DateFilter key={propName} source={propName} />
            } else {
              element = (
                <DateTimeInput
                  key={propName}
                  source={propName}
                  validate={[
                    mode === 'create'
                      ? isRequired(
                          nullable,
                          schema?.required?.includes(propName),
                          translate('nclusion.required')
                        )
                      : () => null
                  ]}
                  {...(schema?.required?.indexOf(propName) >= 0
                    ? { required: true }
                    : {})}
                />
              )
            }
            break
          case 'boolean':
            element = (
              <BooleanInput
                key={propName}
                source={propName}
                validate={[
                  mode === 'create'
                    ? isRequired(
                        nullable,
                        schema?.required?.includes(propName),
                        translate('nclusion.required')
                      )
                    : () => null
                ]}
                {...(schema?.required?.indexOf(propName) >= 0 && !filterMode
                  ? { required: true }
                  : {})}
              />
            )
            break
          default:
            element = <TextInput key={propName} source={propName} /> // create a default show element
            break
        }
      }
    }

    return element
  }
}

export const removeUnfilterableProps = (schema: any) =>
  schema
    ? {
        ...schema,
        properties: Object.keys(schema.properties).reduce(
          (props: any, prop: keyof typeof schema.properties) => {
            if (
              !schema.properties[prop].computed &&
              !schema.properties[prop].$ref
            ) {
              props[prop] = schema.properties[prop]
            }
            return props
          },
          {}
        )
      }
    : schema

const FeathersCrud = function ({
  Component = NclusionList,
  InnerComponent = Datagrid,
  resource = '',
  mode = 'show',
  defaultValues = {},
  isList,
  ...rest
}: // for create and edit the back end validator can be used for client side validation
// for create the back end data resolver may be useful to pre-populate fields (but not much as there's little overlap)
{
  Component: any // correct type
  InnerComponent: any // correct type
  resource: string
  mode: 'show' | 'create' | 'edit'
  defaultValues: any
  rest: any
  isList: boolean
}) {
  type Entity = Static<typeof schema>
  const schemas = app.get('schemas') || {}
  const schema = schemas[resource]
  const props = Object.keys(schema?.properties || {}).filter(
    (p) => Component !== NclusionList || !p.endsWith('_id')
  )

  const componentProps = {
    ...rest,
    ...(resource
      ? {
          resource: `${
            schemas[resource]?.category ? `${schemas[resource].category}-` : ''
          }${kebabCase(resource)}`
        }
      : {}),
    ...(mode === 'edit' || mode === 'create' ? { redirect: 'show' } : {}),
    ...(isList
      ? {
          filters: Object.keys(removeUnfilterableProps(schema).properties).map(
            schemaPropToElement('create', schema, schemas, true)
          )
        }
      : {})
  }
  const innerComponentProps = {
    ...(mode === 'show' ? { rowClick: 'show' } : {})
  }

  return (
    schema && (
      <Component {...componentProps}>
        <InnerComponent {...innerComponentProps}>
          {props.map(
            schemaPropToElement<Entity>(mode, schema, schemas, false, {
              resource
            })
          )}
        </InnerComponent>
      </Component>
    )
  )
}

export default FeathersCrud

export const List = (props: any) => (
  <FeathersCrud
    {...props}
    Component={NclusionList}
    InnerComponent={props.InnerComponent || Datagrid}
    isList={true}
  />
)
// the show page will need a custom schema-aware delete button that uses prop.isReferencedBy to prevent deletes
// and show a list of the related records
export const Show = (props: any) => (
  <FeathersCrud
    {...props}
    Component={RAShow}
    InnerComponent={props.InnerComponent || SimpleShowLayout}
  />
)
export const Create = (props: any) => (
  <FeathersCrud
    {...props}
    Component={RACreate}
    InnerComponent={props.InnerComponent || SimpleForm}
    mode="create"
  />
)
export const Edit = (props: any) => (
  <FeathersCrud
    {...props}
    Component={RAEdit}
    InnerComponent={props.InnerComponent || SimpleForm}
    mode="edit"
  />
)

// if all props in the schema are read only don't return the mutate components
export const feathersResourceComponents = (name: string) => ({
  list: (props: any) => <List {...props} resource={name} />,
  show: (props: any) => <Show {...props} resource={name} />,
  create: (props: any) => <Create {...props} resource={name} />,
  edit: (props: any) => <Edit {...props} resource={name} />
})
