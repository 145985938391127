import { Box, MenuItem, Select, TextField } from "@mui/material"
import { Button, useNotify, useRefresh, useTranslate } from "react-admin"
import { format } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react"
import app from '../../feathersClient'
import { Reconciliation as ReconciliationType } from '@nclusion/feathers-client'

const ReconciliationSummary = ({ expected, meta: { startingBalance, startTime, purchase, refund, agent_commission, redemption }, associate }: ReconciliationType) => {
  const translate = useTranslate()
  
  return <>
    <div>{associate!.name}</div>
    <div>{format(new Date(startTime), 'MM-dd-yyyy')} - {format(new Date, 'MM-dd-yyyy')}</div>
    <hr />
    <div>{translate('cashManagement.StartingBalance')}: {startingBalance}</div>
    <div>{translate('cashManagement.Sales')}: {purchase - refund}</div>
    <div>{translate('cashManagement.Commission')}: {agent_commission}</div>
    <div>{translate('cashManagement.Redemptions')}: {redemption}</div>
    <hr />
    <div>{translate('cashManagement.ExpectedCash')}: {expected}</div>
  </>
}

const reasons = [
  {
    id: 'unrecoverable',
    name: 'cashManagement.unrecoverable'
  },
  {
    id: 'theft',
    name: 'cashManagement.theft'
  },
  {
    id: 'expense',
    name: 'cashManagement.expense'
  },
  {
    id: 'repayment',
    name: 'cashManagement.repayment'
  },
  {
    id: 'overcharge',
    name: 'cashManagement.overcharge'
  }
]

const Reconciliation = () => {
  const translate = useTranslate()
  const { state: initial } = useLocation()
  const [reconciliation, setReconciliation] = useState(initial)
  // we also need to make this load the reconciliation if it's not present in route state
  const [cash, setCash] = useState(0)
  const [reasonId, setReasonId] = useState<string>('')
  const [explanation, setExplanation] = useState<string>('')
  const complete = !!reconciliation.completedAt
  const navigate = useNavigate()
  const notify = useNotify()
  const initialPathname = window.location.hash
  const refresh = useRefresh()

  const listener = complete ? () => {} : (updated: ReconciliationType) => {
    setReconciliation(updated)
    if (updated.id === reconciliation.id && updated.abandoned) {
      notify('cashManagement.reconciliationAbandonedByAssociate', { type: 'warning' })
      navigate('/reconciliation?order=DESC&page=1&perPage=10&sort=createdAt')
      refresh()
    }
  }

  useEffect(() => {
    app.service('reconciliation').on('patched', listener)
    return () => {
      if (window.location.hash !== initialPathname && !/^#\/cash\-exchange\/\d+/.test(window.location.hash) && !complete) {
        const fn = async () => {
          const current = await app.service('reconciliation').get(reconciliation.id)
          if (current.abandoned) {
            navigate('/reconciliation?order=DESC&page=1&perPage=10&sort=createdAt')
            refresh()
          } else {
            app.service('reconciliation').patch(reconciliation.id, { abandoned: true }).then(() => {
              notify('cashManagement.reconciliationAbandonedByController', { type: 'warning' })
              navigate('/reconciliation?order=DESC&page=1&perPage=10&sort=createdAt')
              refresh()
            })
          }
        }
        fn()
      }
      app.service('reconciliation').removeListener('patched', listener)
    }
  }, [listener])

  const submitActualCash = async () => {
    setReconciliation(await app.service('reconciliation').patch(reconciliation.id, { actual: cash }))
  }

  const submitReason = async () => {
    setReconciliation(await app.service('reconciliation').patch(reconciliation.id, { reason: reasonId, explanation }))
  }

  const toCashExchange = async () => {
    const exchange = await app.service('cash-exchange').create({ associate_id: reconciliation.associate_id })
    navigate(`/cash-exchange/${exchange.id}`, { state: exchange })
  }

  const toSuccess = () => {
    notify('cashManagement.ReconciliationCompletre', { type: 'success' })
    navigate('/reconciliation?order=DESC&page=1&perPage=10&sort=createdAt')
  }
  
  return <Box sx={{ maxWidth: 320, padding: 4 }}>
    <h2>{translate('cashManagement.reconciliation')}</h2>
    {!complete && <div>
      {!reconciliation.initial && <ReconciliationSummary {...reconciliation} />}
      {reconciliation.initial && <h2>{translate('InitialReconciliation')}</h2>}
      {!reconciliation.actual && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <TextField placeholder={translate('HomeScreen.CashOnHand')} value={cash || ''} onChange={(e: any) => setCash(Number(e.target.value || 0)) as any} />
        <Button sx={{ marginTop: 1 }} variant="contained" onClick={submitActualCash} label={translate('nclusion.submit')} />
      </div>}
      {reconciliation.actual && <div>{translate('cashManagement.ActualCash')}: {reconciliation.actual}</div>}
      {reconciliation.actual && reconciliation.actual !== reconciliation.expected && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <Select
          label={translate('cashManagement.EnterDiscrepancyReason')}
          value={reasonId}
          onChange={(e: any) => setReasonId(e.target.value)}
        >
          {reasons.map(({ id, name }) => <MenuItem key={id} value={id}>{translate(name)}</MenuItem>)}
        </Select>
        <TextField placeholder={translate('cashManagement.Explanation')} value={explanation} onChange={(e: any) => setExplanation(e.target.value || '') as any} />
        <Button sx={{ marginTop: 1 }} variant="contained" onClick={submitReason} label={translate('nclusion.submit')} />
      </div>}
    </div>}
    {complete && <div>
      <div>{translate('cashManagement.TargetCash')}: {reconciliation.currency} {reconciliation.target}</div>
      <div>{translate('cashManagement.ActualCash')}: {reconciliation.currency} {reconciliation.actual}</div>
      <div>{translate('cashManagement.ExchangeCashQuestion')}</div>
      <div>{translate('cashManagement.ExchangeCashQuestionText')}</div>
      <div style={{ flexDirection: 'row', marginTop: 1 }}>
        <Button
          variant="contained"
          onClick={toCashExchange}
          style={{ flex: 1, margin: 1 }}
        >
          <span>{translate('Common.Yes')}</span>
        </Button>
        <Button
          onClick={toSuccess}
          style={{ flex: 1, margin: 1 }}
        >
          <span>{translate('Common.No')}</span>
        </Button>
      </div>
    </div>}
  </Box>
}
export default Reconciliation