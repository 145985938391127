import React, { useEffect, useState } from 'react'
import {
  AutocompleteInput,
  Datagrid,
  DatagridHeaderProps,
  DateTimeInput,
  FieldProps,
  FunctionField,
  Identifier,
  Link,
  List,
  ListControllerResult,
  NumberField,
  RaRecord,
  RadioButtonGroupInput,
  ReferenceInput,
  WithListContext,
  defaultLightTheme,
  useListContext,
  useListController,
  useRecordContext,
  useRefresh,
  useTheme,
  useTranslate
} from 'react-admin'
import { TableCell, TableHead, TableRow } from '@mui/material'
import { startOfDay, endOfDay } from 'date-fns'
import app from '../../feathersClient'
import { formatNumbersOrSales } from '../../util'
import { Role } from '@nclusion/feathers-client'
import { clamp } from 'lodash'

const fieldStyle: React.CSSProperties = { minWidth: '80px', textAlign: 'right' }

const getRemainingPayout = (record: RaRecord<Identifier>) => {
  const payout = record?.total_payout || 0
  const redeemed = record?.total_redemption || 0

  return formatNumbersOrSales(
    clamp(Number(payout) - Number(redeemed), 0, Infinity),
    record?.currency
  )
}

const CurrencyField = ({
  source,
  label
}: {
  source: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record[`total_${source}`] || 0, record?.currency)}
    </div>
  )
}

const NetPurchaseField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record?.gross_purchase || 0, record?.currency)}
    </div>
  )
}

const CommissionField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {`${(Number(record?.commission) * 100).toFixed(0) || 0} %`}
    </div>
  )
}

const AgentCommissionField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record?.total_agent_commission, record?.currency)}
    </div>
  )
}

const RemainingPayoutField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return <div style={fieldStyle}>{getRemainingPayout(record)}</div>
}

const TotalWinningsField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()

  return (
    <div style={fieldStyle}>
      {formatNumbersOrSales(record?.total_payout || 0, record?.currency)}
    </div>
  )
}

const TotalCashField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()
  const [theme] = useTheme()

  return (
    <div
      style={{
        ...fieldStyle,
        color:
          Number(record?.total_cash) >= 0
            ? theme === 'dark'
              ? '#36F4A4'
              : '#09AC71'
            : '#F10E60'
      }}
    >
      {formatNumbersOrSales(record?.total_cash, record?.currency)}
    </div>
  )
}

const AgentField = ({ source, label }: { source?: string; label?: string }) => {
  const record = useRecordContext()
  const translate = useTranslate()
  const { perPage, sort } = useListContext()

  if (record['id'] === null) {
    return <div>{''}</div>
  } else {
    return (
      <FunctionField
        source="id"
        render={(record: { name: string; id: number }) => (
          <Link to={`/associate/${record?.id}/show`}>
            {`${record?.name} #${record?.id}`}
          </Link>
        )}
        emptyText={`${translate('nclusion.deactivatedAssociate')} #${
          record?.id
        }`}
        label={translate('nclusion.Agent')}
        queryoptions={{ meta: { $limit: perPage + 1, sort: sort } }}
      />
    )
  }
}

const CashControllerField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()
  const translate = useTranslate()
  const { filterValues } = useListContext()

  if (
    filterValues.associate_id &&
    record?.id === null &&
    record?.cashcontroller_id === filterValues.associate_id
  ) {
    return (
      <div style={{ fontWeight: 'bold' }}>{translate('nclusion.total')}</div>
    )
  } else {
    return (
      <FunctionField
        render={(record: {
          cashcontroller_id: number
          cashcontroller_name: string
        }) => (
          <Link to={`/associate/${record?.cashcontroller_id}/show`}>
            {record?.cashcontroller_name && record?.cashcontroller_id
              ? `${record?.cashcontroller_name} #${record?.cashcontroller_id}`
              : ''}
          </Link>
        )}
      />
    )
  }
}

const BankField = ({ source, label }: { source?: string; label?: string }) => {
  const record = useRecordContext()
  const translate = useTranslate()

  if (record['id'] === null) {
    return <div>{''}</div>
  } else {
    return (
      <FunctionField
        source="id"
        render={(record: { bank_id: number; bank_name: string }) => (
          <Link to={`/bank/${record?.bank_id}/show`}>{record?.bank_name}</Link>
        )}
        label={translate('nclusion.bank')}
      />
    )
  }
}

const SubtenantField = ({
  source,
  label
}: {
  source?: string
  label?: string
}) => {
  const record = useRecordContext()
  const translate = useTranslate()

  if (record['id'] === null) {
    return <div>{''}</div>
  } else {
    return (
      <FunctionField
        source="id"
        render={(record: { subtenant_id: number; subtenant_name: string }) => (
          <Link to={`/sub-tenant/${record?.subtenant_id}/show`}>
            {record?.subtenant_name}
          </Link>
        )}
        label={translate('nclusion.subtenant')}
      />
    )
  }
}

export default ({ fullReport }: { fullReport?: boolean }) => {
  const translate = useTranslate()
  const [theme] = useTheme()
  const { setFilters, filterValues, data, showFilter, hideFilter } =
    useListController()
  const refresh = useRefresh()

  useEffect(() => {
    if (!filterValues.start && !filterValues.end) {
      setFilters(
        { start: startOfDay(new Date()), end: endOfDay(new Date()) },
        {}
      )
    }
  }, [])

  useEffect(() => {
    app.service('refresh-publication').on('created', refresh)
    app.service('session').on('patched', refresh)

    return () => {
      app.service('refresh-publication').removeListener('patched', refresh)
      app.service('session').removeListener('patched', refresh)
    }
  })

  const [role, setRole] = useState<string | null>(null)
  useEffect(() => {
    const fetchRole = async () => {
      const roleName = await getRole(filterValues.associate_id)
      setRole(roleName)

      if (roleName === 'Cash controller') {
        showFilter('refine', 'all')
        showFilter('secondary_associate', {})
      } else {
        hideFilter('refine')
        hideFilter('secondary_associate')
      }
    }

    if (filterValues?.associate_id) {
      fetchRole()
    } else {
      hideFilter('refine')
      hideFilter('secondary_associate')
    }
  }, [filterValues, hideFilter, showFilter])

  const evenColumnBackground = {
    backgroundColor: theme === 'dark' ? '#313131' : '#f4f4f4'
  }
  const oddColumnBackground = {
    backgroundColor:
      theme === 'dark'
        ? '#212121'
        : defaultLightTheme.palette?.background?.default
  }

  const headerMapping: {
    [key: string]: string[]
  } = {
    info: ['agent', 'bank', 'cashController', 'subtenant'],
    commissions: [
      'purchase',
      'refund',
      'grossSales',
      'commission',
      'agentCommission'
    ],
    redemption: ['payout', 'redemption', 'remaining'],
    netSales: [
      'operational_days',
      'totalGrossSales',
      'totalAgentCommission',
      'totalPayout',
      'totalBalance'
    ]
  }

  const columnStyle = (headerArray: string[]) => {
    let style: {
      [key: string]: { backgroundColor?: string }
    } = {}
    headerArray.forEach((source: string) => {
      style[`& .column-${source}`] = evenColumnBackground
      style[`& .RaDatagrid-row:hover .column-${source}`] = {
        backgroundColor: theme === 'dark' ? '#404040' : '#eeeeee'
      }
    })

    return style
  }

  const fillInTotalRow = (header: string, record: RaRecord<Identifier>) => {
    switch (header) {
      case 'agent':
        return translate('nclusion.total')
      case 'bank':
      case 'cashController':
      case 'subtenant':
        return ''
      case 'grossSales':
      case 'totalGrossSales':
        return formatNumbersOrSales(record?.gross_purchase, record?.currency)
      case 'commission':
        return translate('nclusion.varies')
      case 'remaining':
        return getRemainingPayout(record)
      case 'operational_days':
        return record?.operational_days || 0
      case 'agentCommission':
      case 'totalAgentCommission':
        return formatNumbersOrSales(
          record?.total_agent_commission,
          record?.currency
        )
      case 'totalPayout':
        return formatNumbersOrSales(record?.total_payout, record?.currency)
      case 'totalBalance':
        return formatNumbersOrSales(record?.total_cash, record?.currency)
      default:
        return formatNumbersOrSales(record[`total_${header}`], record?.currency)
    }
  }

  const TotalRow = ({ data }: ListControllerResult<RaRecord<Identifier>>) =>
    data && data[0].id === null ? (
      <TableRow sx={{ backgroundColor: '#73999C' }}>
        {(fullReport
          ? Object.keys(headerMapping)
          : Object.keys(headerMapping).filter(
              (f) => f === 'netSales' || f === 'info'
            )
        ).map((key) =>
          headerMapping[key].map((k: string) => (
            <TableCell
              style={
                k === 'agent'
                  ? { fontWeight: 600 }
                  : { ...fieldStyle, fontWeight: 600 }
              }
              key={`${key}-${k}`}
            >
              {fillInTotalRow(k, data[0])}
            </TableCell>
          ))
        )}
      </TableRow>
    ) : null

  const DatagridHeader = ({ children }: DatagridHeaderProps) => (
    <TableHead
      style={{
        position: 'sticky',
        top: 0
      }}
    >
      <TableRow>
        {Object.keys(headerMapping)
          .filter((f) => (fullReport ? null : f === 'netSales' || f === 'info'))
          .map((headerLabel) =>
            headerMapping[headerLabel].map((header: string, index: number) => (
              <TableCell
                key={header}
                sx={
                  headerLabel === 'commissions' || headerLabel === 'netSales'
                    ? evenColumnBackground
                    : oddColumnBackground
                }
              >
                {index === 0 ? translate(`nclusion.${headerLabel}`) : null}
              </TableCell>
            ))
          )}
      </TableRow>
      <TableRow>
        {React.Children.map(children, (child) =>
          React.isValidElement<FieldProps>(child) ? (
            <TableCell
              sx={{
                backgroundColor:
                  headerMapping['commissions'].includes(child.props.source!) ||
                  headerMapping['netSales'].includes(child.props.source!)
                    ? evenColumnBackground
                    : oddColumnBackground
              }}
              key={`${child?.props?.label}`}
            >
              {child.props.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
      <WithListContext render={TotalRow} />
    </TableHead>
  )

  const optionRenderer = (choice: { name: string; id: number }) =>
    `${choice.name} #${choice.id}`

  const getRole = async (associateId?: number): Promise<string | null> => {
    try {
      const response: {
        data: {
          role: Role
        }[]
      } = await app.service('associate-roles').find({
        query: {
          associate_id: associateId
        }
      })

      if (response.data && response.data.length > 0) {
        return response.data[0].role.name
      } else {
        throw new Error('No role found for this associate')
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const AgentsFilter = ({ source }: { source?: string }) => {
    const { data, filterValues } = useListContext()

    return (
      <ReferenceInput
        label={translate('nclusion.associates')}
        reference="associate"
        source="secondary_associate_id"
        filter={{
          id: {
            $in: data?.map((d) =>
              d.cashcontroller_id === filterValues.associate_id
                ? d.id
                : d.cashcontroller_id
            )
          }
        }}
        filterToQuery={(searchText: string) => ({
          name: { $ilike: `%${searchText}%` }
        })}
      >
        <AutocompleteInput optionText={optionRenderer} style={{ width: 200 }} />
      </ReferenceInput>
    )
  }

  const Refinefilter = ({ source }: { source?: string }) => (
    <RadioButtonGroupInput
      source={source}
      label={translate('nclusion.refine')}
      choices={[
        { id: 'cashControllers', name: translate('nclusion.cashcontrollers') },
        { id: 'agents', name: translate('nclusion.agents') },
        { id: 'all', name: translate('nclusion.all') }
      ]}
      disabled={role !== 'Cash controller'}
      defaultValue="all"
      defaultChecked={true}
    />
  )

  const Filters = [
    <ReferenceInput
      source="subtenant_id"
      reference="sub-tenant"
      label={translate('nclusion.subtenant')}
      key="sub-tenant-filter"
    />,
    <ReferenceInput
      label={translate('nclusion.associates')}
      reference="associate"
      source="associate_id"
      filterToQuery={(searchText: string) => ({
        name: { $ilike: `%${searchText}%` }
      })}
      alwaysOn
    >
      <AutocompleteInput optionText={optionRenderer} style={{ width: 200 }} />
    </ReferenceInput>,
    <AgentsFilter source="secondary_associate" />,
    <RadioButtonGroupInput
      source="balance"
      label={translate('nclusion.balance')}
      choices={[
        { id: 'gain', name: translate('nclusion.gain') },
        { id: 'loss', name: translate('nclusion.loss') },
        { id: 'all', name: translate('nclusion.all') }
      ]}
      defaultValue="all"
      defaultChecked={true}
    />,
    <Refinefilter source="refine" />,
    <DateTimeInput source="start" alwaysOn />,
    <DateTimeInput source="end" alwaysOn />
  ]

  return (
    <List filters={Filters}>
      <Datagrid
        header={<DatagridHeader />}
        isRowSelectable={(record) => record?.id !== null}
        bulkActionButtons={false}
        rowSx={(record) => ({
          display:
            record?.id === null &&
            (record?.cashcontroller_id === filterValues.associate_id ||
              record?.cashcontroller_id === null)
              ? 'none'
              : ''
        })}
        sx={{
          ...columnStyle(headerMapping['commissions']),
          ...columnStyle(headerMapping['netSales']),
          '& .column-agent': { minWidth: 180 },
          '& .column-supervisor': { minWidth: 180 },
          '& .RaDatagrid-table': { borderCollapse: 'unset' }
        }}
      >
        <CashControllerField
          source="cash-controller"
          label={translate('nclusion.cashcontroller')}
        />
        <AgentField source="agent" label={translate('nclusion.Agent')} />
        <BankField source="bank" label={translate('nclusion.bank')} />
        <SubtenantField
          source="subtenant"
          label={translate('nclusion.subtenant')}
        />
        {fullReport && (
          <CurrencyField
            source="purchase"
            label={`${translate('nclusion.sales')} (${data?.[0]?.currency})`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="refund"
            label={`${translate('nclusion.refunded')} (${data?.[0]?.currency})`}
          />
        )}
        {fullReport && (
          <NetPurchaseField
            source="grossSales"
            label={`${translate('nclusion.grossSales')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CommissionField
            source="commission"
            label={`${translate('nclusion.commission')} (%)`}
          />
        )}
        {fullReport && (
          <AgentCommissionField
            source="agentCommission"
            label={`${translate('nclusion.agentCommission')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="payout"
            label={`${translate('nclusion.totalPayout')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <CurrencyField
            source="redemption"
            label={`${translate('nclusion.redemption')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        {fullReport && (
          <RemainingPayoutField
            source="remainingPayout"
            label={`${translate('nclusion.remainingPayout')} (${
              data?.[0]?.currency
            })`}
          />
        )}
        <NumberField
          source="operational_days"
          label={translate('nclusion.operationalDays')}
        />
        <NetPurchaseField
          source="totalGrossSales"
          label={`${translate('nclusion.grossSales')} (${data?.[0]?.currency})`}
        />
        <AgentCommissionField
          source="totalAgentCommission"
          label={`${translate('nclusion.agentCommission')} (${
            data?.[0]?.currency
          })`}
        />
        <TotalWinningsField
          source="totalPayout"
          label={`${translate('nclusion.totaPayout')} (${data?.[0]?.currency})`}
        />
        <TotalCashField
          source="totalBalance"
          label={`${translate('nclusion.balance')} (${data?.[0]?.currency})`}
        />
      </Datagrid>
    </List>
  )
}
