import { Box, MenuItem, Select, TextField, Modal } from "@mui/material"
import { Button, useNotify, useRefresh, useTranslate } from "react-admin"
import { format } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react"
import app from '../../feathersClient'
import { CashExchange as CashExchangeType } from "@nclusion/feathers-client"
import { last } from "lodash"

const CashExchange = () => {
  const translate = useTranslate()
  const { state: initial, pathname } = useLocation()
  const [exchange, setExchange] = useState<null | CashExchangeType>(initial)
  const complete = !!exchange?.completedAt
  const navigate = useNavigate()
  const notify = useNotify()
  const initialPathname = window.location.hash
  const refresh = useRefresh()

  const confirm = async () => {
    setExchange(await app.service('cash-exchange').patch(exchange!.id, { conductor_confirmed: true }))
  }

  const listener = exchange ? (updated: CashExchangeType) => {
    if (updated?.id === exchange?.id) {
      if (updated.associate_confirmed) {
        notify('cashManagement.exchangeSuccess', { type: 'success' })
        navigate('/cash-exchange?order=DESC&page=1&perPage=10&sort=createdAt')
        return
      }
      if (updated.abandoned) {
        notify('cashManagement.cashExchangeAbandonedByAssociate', { type: 'warning' })
        navigate('/cash-exchange?order=DESC&page=1&perPage=10&sort=createdAt')
        return
      }
      setExchange(updated)
    }
  } : () => {}

  useEffect(() => {
    if (`#${pathname}` !== initialPathname && exchange) {
      app.service('cash-exchange').patch(exchange.id, { abandoned: true }).then(() => {
        notify('cashManagement.cashExchangeAbandonedByController', { type: 'warning' })
        app.service('cash-exchange').removeListener('patched', listener)
        navigate('/cash-exchange?order=DESC&page=1&perPage=10&sort=createdAt')
        refresh()
      })
    }
  }, [pathname])

  useEffect(() => {
    const fn = async () => {
      app.service('cash-exchange').on('patched', listener)
    }
    fn()

    return () => {
      const fn2 = async () => {
        const current = await app.service('cash-exchange').get(exchange!.id)
        if (window.location.hash !== initialPathname && !current.completedAt) {
          if (current.abandoned) {
            navigate('/cash-exchange?order=DESC&page=1&perPage=10&sort=createdAt')
            refresh()
          } else {
            app.service('cash-exchange').patch(exchange!.id, { abandoned: true }).then(() => {
              navigate('/cash-exchange?order=DESC&page=1&perPage=10&sort=createdAt')
              notify('cashManagement.cashExchangeAbandonedByController', { type: 'warning' })
              refresh()
            })
          }
        }
        app.service('cash-exchange').removeListener('patched', listener)
      }
      fn2()
    }
  }, [listener])

  // include listener for navigation away, mark abandoned when hit

  const diff = exchange ? exchange.target - exchange.actual : 0
  
  return <Box sx={{ maxWidth: 320, padding: 4 }}>
    <h2>{translate('cashManagement.exchange')}</h2>
    {exchange && !exchange.conductor_confirmed && <div>
      {/* translations needed */}
      <div>Target: {exchange.target} {exchange.currency}</div>
      <div>Actual: {exchange.actual} {exchange.currency}</div>
      <div>{diff > 0 ? translate('cashManagement.PleaseHandAgentMoney') : translate('cashManagement.PleaseCollectMoney')} {Math.abs(diff)} {exchange.currency}</div>
      <Button sx={{ marginTop: 1 }} variant="contained" onClick={confirm} label={diff > 0 ? translate('cashManagement.ConfirmHandover') : translate('cashManagement.ConfirmCollection')} />
    </div>}
    {!!exchange && !!exchange.conductor_confirmed && !exchange.associate_confirmed && <div>
      <h2>{translate('cashManagement.AwaitingAssociate')}</h2>
      <p>{translate('cashManagement.AwaitingControllerText').replace('${associate}', exchange?.associate?.name || '')}</p>
    </div>}
  </Box>
}
export default CashExchange