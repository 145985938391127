import { DateField, Link, NumberField, Show, TextField, WithRecord, useNotify, useRecordContext, useRefresh, useTheme, useTranslate } from 'react-admin'
import {
  Button,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField as MUITextInput,
  Modal,
  CardActions,
  Autocomplete
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { compact, omit, sortBy, startCase, sumBy, uniqBy } from 'lodash'
import { Associate, RelationshipTree, TicketDenormalized, Transaction, Wager } from '@nclusion/feathers-client'
import client from '../../feathersClient'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import QRCode from "react-qr-code"

const hasOpenSession = (ticket: TicketDenormalized) => {
  const sessions = uniqBy(ticket.wagers.map(({ session }) => session), ({ status }) => status)
  const now = new Date()

  return !!sessions.filter(({ close_timestamp }) => !close_timestamp || new Date(close_timestamp) > now)
}

const sesssionName = (name: string) => {
  const [drawingName, gameName, date] = name.split(' ')
  const [,,state,time] = drawingName.split('.')
  const [,,game] = gameName.split('.')

  return `${startCase(game)} ${state?.toUpperCase() || ''} ${startCase(time)} ${date}`
}

const refund = async (ticket: TicketDenormalized, associate?: null | Associate) => {
  let updatedBankId: number = 0
  if (associate && associate.id !== ticket.transaction.associate?.id || 0) {
    const { data: [bankAssignment] } = await client.service('bank-assignment').find({ query: { associate_id: associate?.id, $limit: 1 } })
    if (bankAssignment) {
      updatedBankId = bankAssignment.bank_id
    }
  }
  const { confirmation_code } = await client.service('transactions-refund').create({
    order_id: ticket?.uuid,
    currency: ticket.currency,
    merchant_id: ticket.lottery.merchant_id,
    amount: ticket.total_wager,
    bank_id: updatedBankId || ticket.transaction.bank?.id,
    associate_id: associate?.id || ticket.transaction.associate?.id
  })
  await client.service('transactions-confirm').create({ confirmation_code })
}

const redeem = async (ticket: TicketDenormalized, associate?: null | Associate) => {
  let updatedBankId: number = 0
  if (associate && associate.id !== ticket.transaction.associate?.id || 0) {
    const { data: [bankAssignment] } = await client.service('bank-assignment').find({ query: { associate_id: associate?.id, $limit: 1 } })
    if (bankAssignment) {
      updatedBankId = bankAssignment.bank_id
    }
  }
  const { data: [bearerInstrument] } = await client.service('accounts-bearer-instrument').find({ query: {
    order_id: ticket.uuid,
    status: 'active' as any
  }})
  const { confirmation_code } = await client.service('transactions-redeem').create({
    order_id: ticket.uuid,
    bank_id: updatedBankId || ticket.transaction.bank?.id,
    associate_id: associate ? associate.id : ticket.transaction.associate?.id,
    merchant_id: ticket.lottery.merchant_id,
    currency: ticket.currency,
    amount: bearerInstrument.available_balance
  })
  await client.service('transactions-confirm').create({ confirmation_code })
}

const getRefreshTicket = (refresh: Function, { id }: TicketDenormalized & { id: string }) => ({ service }: { service: string }) => {
  if (service === 'ticket-denormalized') {
    refresh()
  }
}

const ActionModalButton = ({ ticket, isRedeem }: { ticket: TicketDenormalized, isRedeem?: boolean }) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const [associateSearch, setAssociateSearch] = useState(ticket.transaction.associate?.name || '')
  const [associates, setAssociates] = useState<Associate[]>([])
  const [associate, setAssociate] = useState<null | Associate>(ticket.transaction.associate || null)
  const refresh = useRefresh()

  useEffect(() => {
    const fn = async () => {
      const associates =
        (
          await client.service('associate-autocomplete').find({
            query: associateSearch
              ? {
                  name: associateSearch,
                  roles: ['Agent']
                }
              : {
                  roles: ['Agent']
                }
          })
        ) || []
      setAssociates(associates)
    }
    fn()
  }, [associateSearch])

  return <>
    <Button onClick={() => setOpen(true)} size="large">{translate(isRedeem ? 'nclusion.redeem': 'nclusion.refund')}</Button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div>
        <div style={{ margin: '10% 30% 0' }}>
          <Card variant="outlined">
            <CardHeader
              title={translate(isRedeem ? 'nclusion.redeemTicket' : 'nclusion.refundTicket')}
            />
            <CardContent>
              <label>{translate(isRedeem ? 'nclusion.redeemAssociate' : 'nclusion.refundAssociate')}</label>
              <Autocomplete
                inputValue={associateSearch}
                onInputChange={(e: any, t: any) => setAssociateSearch(t || '')}
                value={associate}
                getOptionLabel={(val) => val.name || ''}
                onChange={(e: any, a: any) => setAssociate(a)}
                renderInput={(props: any) => <MUITextInput {...props} />}
                options={associates}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={() => setOpen(false)}>{translate('nclusion.cancel')}</Button>
              <Button
                onClick={async () => {
                  try {
                    isRedeem ? await redeem(ticket, associate) : await refund(ticket, associate)
                    setOpen(false)
                    await new Promise(resolve => setTimeout(resolve, 1000))
                    refresh()
                    setTimeout(refresh, 5000)
                  } catch (e) {
                    notify(translate(`${e}`), { type: 'error'})
                  }
                }}
              >{translate(isRedeem ? 'nclusion.redeem' : 'nclusion.refund')}</Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </Modal>
  </>
}

const ShowContent = () => {
  const [theme] = useTheme()
  const navigate = useNavigate()
  const ticket = useRecordContext<TicketDenormalized>() || {}
  const refresh = useRefresh()
  const translate = useTranslate()

  useEffect(() => {
    if (!ticket?.id) { return }
    const fetchTicket = getRefreshTicket(refresh, ticket as TicketDenormalized & { id: string })
    client.service('refresh-publication').on('created', fetchTicket)

    return () => {
      client.service('refresh-publication').removeListener('created', fetchTicket)
    }
  }, [ticket?.id])

  return <div style={{ margin: '2rem' }}>
    <h2 style={{ fontSize: 18 }}>Ticket: <TextField sx={{ fontWeight: 'bold', fontSize: 18 }} source="uuid" /></h2>
    <p style={{ marginBottom: '2rem' }}>{translate('nclusion.status')}: <TextField sx={{ fontSize: 16, fontWeight: 'bold' }} source="status" /></p>
    <Box style={{ overflow: 'hidden' }}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={8}
        columns={3}
      >
        <Grid item sx={{ minWidth: '25%', marginTop: 2}}>
          <p>{translate('nclusion.lottery')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/lottery/${record.lottery_id}/show`}>{record.lottery.name}</Link>} /> </p>
          <p>{translate('nclusion.merchant')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/merchant/${record.transaction.merchant_id}/show`}>{record.transaction.merchant.name}</Link>} /></p>
          <p>{translate('nclusion.mfi')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/mfi/${record.transaction.mfi_id}/show`}>{record.transaction.mfi.name}</Link>} /></p>
          <p>{translate('nclusion.tenant')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/tenant/${record.transaction.tenant_id}/show`}>{record.transaction.tenant.name}</Link>} /></p>
          <p>{translate('nclusion.subTenant')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/sub-tenant/${record.transaction.subtenant_id}/show`}>{record.transaction.subtenant.name}</Link>} /></p>
        </Grid>
        <Grid item sx={{ minWidth: '25%', marginTop: 2}}>
          <p>{translate('nclusion.agent')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/associate/${record.transaction.associate_id}/show`}>{record.transaction.associate.name}</Link>} /></p>
          <p>{translate('nclusion.bank')}: <WithRecord render={record => <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/bank/${record.transaction.bank_id}/show`}>{record.transaction.bank.name}</Link>} /></p>
          <p>{translate('nclusion.cashControllerRole')}: <WithRecord render={record => {
            const controller = record.relations.find((relation: RelationshipTree) => relation.relationship?.name === 'nclusion.controllerRelationship')?.relative
            return controller ? <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/associate/${controller.id}/show`}>{controller.name}</Link> : <></>
          }} /></p>
          <p>{translate('nclusion.supervisor')}: <WithRecord render={record => {
            const supervisor = record.relations.find((relation: RelationshipTree) => relation.relationship?.name === 'legacy.supervisor')?.relative
            return supervisor ? <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/associate/${supervisor.id}/show`}>{supervisor.name}</Link> : <></>
          }} /></p>
          <p>{translate('nclusion.customer')}: <WithRecord render={record => record.transaction.customer ? <Link sx={{ fontSize: 16, fontWeight: 'bold' }} to={`/customer/${record.transaction.customer_id}/show`}>{record.transaction.customer.name}</Link> : <></>} /></p>
        </Grid>
        <Grid item sx={{ minWidth: '25%', marginTop: 2}}>
          <p>{translate('nclusion.purchase')}: <DateField sx={{ fontWeight: 'bold' }} showTime={true} source="transaction.createdAt" /></p>
          <p>{translate('nclusion.wagers')}: <WithRecord render={record => record.wagers.length} /></p>
          <p>{translate('nclusion.total_wager')}: <TextField sx={{ fontWeight: 'bold' }} source="transaction.currency" /> <NumberField sx={{ fontWeight: 'bold' }} source="total_wager" /></p>
          <p>{translate('nclusion.total_payout')}: <TextField sx={{ fontWeight: 'bold' }} source="transaction.currency" /> <NumberField sx={{ fontWeight: 'bold' }} source="total_paid" /></p>
          <p>{translate('nclusion.total_redemption')}: <TextField sx={{ fontWeight: 'bold' }} source="transaction.currency" /> <NumberField sx={{ fontWeight: 'bold' }} source="total_redemption" /></p>
        </Grid>
        <Grid item sx={{ minWidth: '25%', display: 'flex', justifyContent: 'flex-end' }}>
          {ticket && <QRCode value={ticket?.uuid || ''} style={{ width: 160, height: 160, border: '10px solid white' }} />}
        </Grid>
      </Grid>
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <WithRecord render={record => {
          const isRefundable = !record.redemptions?.length && hasOpenSession(record) && record.status !== 'refunded'
          return isRefundable ? <ActionModalButton ticket={record} /> : <></>
        }} />
        <WithRecord render={record => {
          const payout = sumBy<Wager>(record.wagers, 'payout_amount')
          const isRedeemable = !!payout && payout > record.total_redemption && new Date(record.expiresAt!) > new Date()
          return isRedeemable ? <ActionModalButton ticket={record} isRedeem={true} /> : <></>
        }} />
      </div>
    </Box>
    <Box>
      <h2 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>{translate('nclusion.transactions')}</h2>
      <WithRecord render={record => {
        const schema = client.get('schemas')?.transaction
        schema.properties = omit(schema.properties, ['id', 'mfi_id', 'currency', 'tenant_id', 'subtenant_id', 'associate_id', 'merchant_id', 'bank_id', 'mfi', 'tenant', 'subtenant', 'merchant', 'order_id', 'customer_id', 'customer'])
        const transactions: Transaction[] = sortBy(compact([record.transaction, ...record.redemptions || [], record.refund]), 'createdAt')
        return <TableContainer component={Paper} sx={{ overflowY: 'scroll', backgroundColor: theme === 'dark' ? '#1e1e1e' : '#f7f7f7' }}>
          <Table sx={{ minWidth: 960 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translate('nclusion.name')}</TableCell>
                <TableCell>{translate('nclusion.type')}</TableCell>
                <TableCell>{translate('nclusion.total_amount')}</TableCell>
                <TableCell>{translate('nclusion.cash_amount')}</TableCell>
                <TableCell>{translate('nclusion.status')}</TableCell>
                <TableCell>{translate('nclusion.associate')}</TableCell>
                <TableCell>{translate('nclusion.bank')}</TableCell>
                <TableCell>{translate('nclusion.source_account')}</TableCell>
                <TableCell>{translate('nclusion.createdAt')}</TableCell>
                <TableCell>{translate('nclusion.updatedAt')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(transaction => <TableRow
                key={transaction.id}
                sx={{ cursor: 'pointer', backgroundColor: theme === 'dark' ? '#212121' : '#fafafa', ':nth-of-type(odd)': { backgroundColor: theme === 'dark' ? '#333' : '#f1f1f1' }, '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => navigate(`/transaction/${transaction.id}/show`)}
              >
                <TableCell>{transaction.name}</TableCell>
                <TableCell>{transaction.type}</TableCell>
                <TableCell>{transaction.total_amount}</TableCell>
                <TableCell>{transaction.cash_amount}</TableCell>
                <TableCell>{transaction.status}</TableCell>
                <TableCell onClick={e => e.stopPropagation()}><Link to={`/associate/${transaction.associate?.id}/show`}>{transaction.associate?.name}</Link></TableCell>
                <TableCell onClick={e => e.stopPropagation()}><Link to={`/bank/${transaction.bank?.id}/show`}>{transaction.bank?.name}</Link></TableCell>
                <TableCell onClick={e => e.stopPropagation()}>{transaction.source_account ? <Link to={`/account/${transaction.source_account.id}/show`}>{transaction.source_account.name}</Link> : <></>}</TableCell>
                <TableCell>{transaction.createdAt ? `${new Date(transaction.createdAt).toLocaleDateString()} ${new Date(transaction.createdAt).toLocaleTimeString()}` : <></>}</TableCell>
                <TableCell>{transaction.updatedAt ? `${new Date(transaction.updatedAt).toLocaleDateString()} ${new Date(transaction.updatedAt).toLocaleTimeString()}` : <></>}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      }} />
    </Box>
    <Box sx={{ marginTop: 8 }}>
      <h2 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>{translate('nclusion.wagers')}</h2>
      <WithRecord render={record => {
        const schema = client.get('schemas')?.wager
        schema.properties = omit(schema.properties, ['id', 'lottery_id', 'ticket_id', 'currency', 'lottery', 'ticket_uuid', 'ticket', 'session_id', 'pattern'])
        const wagers: Transaction[] = sortBy(record.wagers, 'createdAt')
        return <TableContainer component={Paper} sx={{ overflowY: 'scroll', backgroundColor: theme === 'dark' ? '#1e1e1e' : '#f7f7f7' }}>
          <Table sx={{ minWidth: 960 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translate('nclusion.session')}</TableCell>
                <TableCell>{translate('nclusion.pick')}</TableCell>
                <TableCell>{translate('nclusion.bet_amount')}</TableCell>
                <TableCell>{translate('nclusion.promotion')}</TableCell>
                <TableCell>{translate('nclusion.promotion_amount')}</TableCell>
                <TableCell>{translate('nclusion.payout_amount')}</TableCell>
                <TableCell>{translate('nclusion.status')}</TableCell>
                <TableCell>{translate('nclusion.createdAt')}</TableCell>
                <TableCell>{translate('nclusion.updatedAt')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wagers.map((wager: any) => <TableRow
                key={wager.id}
                sx={{ cursor: 'pointer', backgroundColor: theme === 'dark' ? '#212121' : '#fafafa', ':nth-of-type(odd)': { backgroundColor: theme === 'dark' ? '#333' : '#f1f1f1' }, '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => navigate(`/wager/${wager.id}/show`)}
              >
                <TableCell onClick={e => e.stopPropagation()}><Link to={`/session/${wager.session.id}/show`}>{sesssionName(wager.session.name)}</Link></TableCell>
                <TableCell>{wager.pick}</TableCell>
                <TableCell>{wager.bet_amount}</TableCell>
                <TableCell>{wager.promotion}</TableCell>
                <TableCell>{wager.promotion_amount}</TableCell>
                <TableCell>{wager.payout_amount}</TableCell>
                <TableCell>{wager.status}</TableCell>
                <TableCell>{wager.createdAt ? `${new Date(wager.createdAt).toLocaleDateString()} ${new Date(wager.createdAt).toLocaleTimeString()}` : <></>}</TableCell>
                <TableCell>{wager.updatedAt ? `${new Date(wager.updatedAt).toLocaleDateString()} ${new Date(wager.updatedAt).toLocaleTimeString()}` : <></>}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      }} />
    </Box>
  </div>
}

const ShowTicketDenormalized = () => {
  const translate = useTranslate()

  return <Show title={`${translate('nclusion.ticket')}`} actions={<div style={{ height: '3rem' }} />}>
    <ShowContent />
  </Show>
}

export default ShowTicketDenormalized
