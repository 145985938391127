import { Datagrid, FilterButton, List, TopToolbar, useTranslate, TextField, ReferenceField, NumberField, BooleanField, DateField, useRecordContext } from "react-admin"
import { StartCashManagementButton } from "../reconciliation/List"
import { schemaFilters } from "../../util"

const AmountExchanged = ({ label }: { label: string }) => {
  const record = useRecordContext()
  
  const amount = record.actual - record.target
  const color = amount < 0 ? '#f44336' : '#66bb6a'
  return !!record.completedAt ? <span style={{ color }}>{Math.abs(amount)}</span> : <></>
}

export default () => <List
  filters={schemaFilters('cash-exchange')}
  actions={<TopToolbar>
    <FilterButton />
    <StartCashManagementButton isCashExchange={true} />
  </TopToolbar>}
  empty={<TopToolbar>
    <StartCashManagementButton isCashExchange={true} />
  </TopToolbar>}
  >
  <Datagrid rowClick="show" bulkActionButtons={false}>
    <ReferenceField source="conductor_id" reference="associate" />
    <ReferenceField source="associate_id" reference="associate" />
    <ReferenceField source="bank_id" reference="bank" />
    <ReferenceField source="subtenant_id" reference="sub-tenant" />
    <ReferenceField source="tenant_id" reference="tenant" />
    <TextField source="currency" />
    <NumberField source="target" />
    <NumberField source="actual" />
    <BooleanField source="associate_confirmed" />
    <BooleanField source="conductor_confirmed" />
    <AmountExchanged label="nclusion.exchangeAmount" />
    <DateField source="createdAt" showTime={true} />
    <DateField source="updatedAt" showTime={true} />
    <TextField source="completedAt" />
    <BooleanField source="abandoned" />
  </Datagrid>
</List>