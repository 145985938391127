import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceField,
  TextField,
  WithRecord,
  useTranslate,
  useNotify,
  useRefresh,
  TopToolbar,
  FilterButton
} from 'react-admin'
import ConfirmationField from '../../components/ConfirmationField'
import { schemaFilters } from '../../util'
import { isNumber } from 'lodash'
import { NclusionList } from '../../components/NclusionList'
import { langToLocale, getLanguage } from '../../i18nProvider'
import Associate from '../../components/Associate'
import { useState, useEffect } from 'react'
import { Associate as AssociateType } from '@nclusion/feathers-client'
import client from '../../feathersClient'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField as MUITextInput,
  Modal,
  CardActions,
  Autocomplete
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

export const StartCashManagementButton = ({ isCashExchange }: { isCashExchange?: boolean }) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const [associateSearch, setAssociateSearch] = useState('')
  const [associates, setAssociates] = useState<AssociateType[]>([])
  const [associate, setAssociate] = useState<null | AssociateType>(null)
  const refresh = useRefresh()
  const navigate = useNavigate()

  useEffect(() => {
    const fn = async () => {
      const associates =
        (
          await client.service('associate-autocomplete').find({
            query: associateSearch
              ? {
                  name: associateSearch,
                  roles: ['Cash controller']
                }
              : {
                  roles: ['Cash controller']
                }
          })
        ) || []
      setAssociates(associates)
    }
    fn()
  }, [associateSearch])

  return <>
    <Button sx={{ marginBottom: 0, lineHeight: 1.35, fontSize: '14px' }} size="small" onClick={() => setOpen(true)}>{translate(isCashExchange ? 'cashManagement.transfer': 'cashManagement.Reconciliation')}</Button>
    <Modal open={open} onClose={() => setOpen(false)}>
      <div>
        <div style={{ margin: '10% 30% 0' }}>
          <Card variant="outlined">
            <CardHeader
              title={translate(isCashExchange ? 'cashManagement.transfer' : 'cashManagement.reconciliation') }
            />
            <CardContent>
              <label>{translate('nclusion.cashController')}</label>
              <Autocomplete
                inputValue={associateSearch}
                onInputChange={(e: any, t: any) => setAssociateSearch(t || '')}
                value={associate}
                getOptionLabel={(val) => val.name || ''}
                onChange={(e: any, a: any) => setAssociate(a)}
                renderInput={(props: any) => <MUITextInput {...props} />}
                options={associates}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
              />
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                // mark reconciliation/exhange abandoned
                setOpen(false)
              }}>{translate('nclusion.cancel')}</Button>
              <Button
                disabled={!associate}
                onClick={async () => {
                  if (!associate) return

                  try {
                    if (isCashExchange) {
                      const exchange = await client.service('cash-exchange').create({ associate_id: associate.id })
                      navigate(`/cash-exchange/${exchange.id}`, { state: exchange })
                    } else {
                      const reconciliation = await client.service('reconciliation').create({ associate_id: associate.id })
                      navigate(`/reconciliation/${reconciliation.id}`, { state: reconciliation })
                    }
                    setOpen(false)
                    await new Promise(resolve => setTimeout(resolve, 1000))
                    refresh()
                    setTimeout(refresh, 5000)
                  } catch (e) {
                    notify(translate(`${e}`), { type: 'error'})
                  }
                }}
              >{translate('nclusion.start')}</Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </Modal>
  </>
}

const ReconciliationList = () => {
  const translate = useTranslate()

  return (
    <NclusionList
      filters={schemaFilters('reconciliation')}
      actions={<TopToolbar>
        <FilterButton />
        <StartCashManagementButton />
      </TopToolbar>}
      empty={<TopToolbar>
        <StartCashManagementButton />
      </TopToolbar>}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <Associate prop="associate" label={translate('nclusion.associate')} />
        <Associate prop="conductor" label={translate('nclusion.conductor')} />
        <ReferenceField source="bank_id" reference="bank" />
        <BooleanField source="initial"  />
        <NumberField source="expected" />
        <NumberField source="actual" />
        <WithRecord
          label={translate('cashManagement.discrepancy')}
          render={({ expected, actual }) =>
            isNumber(expected) && isNumber(actual) ? (
              <span
                style={{
                  color: expected - actual > 0 ? '#f44336' : '#66bb6a'
                }}
              >
                {Intl.NumberFormat(langToLocale(getLanguage())).format(Math.abs(expected - actual))}
              </span>
            ) : (
              <></>
            )
          }
        />
        <TextField source="reason" />
        <TextField source="explanation" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
        <DateField source="completedAt" showTime={true} />
        <BooleanField source="abandoned" />
        <WithRecord
          label="Approved"
          render={(record) =>
            record.completedAt ? (
              <ConfirmationField source="approved" showOnDecline={true} />
            ) : (
              <></>
            )
          }
        />
        <ReferenceField source="approver_id" reference="associate" />
        <DateField source="approvedAt" showTime={true} />
        <ReferenceField source="subtenant_id" reference="sub-tenant" />
        <ReferenceField source="tenant_id" reference="tenant" />
      </Datagrid>
    </NclusionList>
  )
}

export default ReconciliationList
